import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";

// Create Context for Authentication
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  // Load user data from sessionStorage when app is initialized
  useEffect(() => {

          // Set Authorization header for Axios
          const setAuthHeader = () => {
            const token = getAuthToken();
            if (token) {
              axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            } else {
              delete axios.defaults.headers.common['Authorization']; // Remove header if no token
            }
          };

    const storedUser = sessionStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
      setAuthHeader(); // Set the JWT token in Axios default headers if available
    }
  }, []);

  // Function to get the token from sessionStorage
  const getAuthToken = () => {
    return sessionStorage.getItem('jwt_token'); // Retrieve token from sessionStorage
  };

  const login = (userData) => {
//    console.log(userData);
    setUser(userData);
    sessionStorage.setItem("user", JSON.stringify(userData));
  };

  const logout = () => {
    setUser(null);
    sessionStorage.clear();
    delete axios.defaults.headers.common['Authorization']; // Remove Authorization header
      // Disable auto select behavior for Google login
    window.google.accounts.id.disableAutoSelect();
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};


export const useAuth = () => {
  return useContext(AuthContext);
};

