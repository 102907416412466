import React from 'react';
import {
  Box,
  Typography,
  Button,
  Container,
  Grid,
  Divider,
  Chip
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import theme from '../theme.js';

const AccessDeniedComponent = () => {

  return (
    <Container
      maxWidth="false"
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 0 // Remove padding to prevent whitespace
      }}
    >
      {/* Centered Box for Login Buttons */}
      <Box
        sx={{
          width: '100%',
          maxWidth: 400, // Limit the width of the box
          textAlign: 'center', // Center text inside the box
          padding: 4,
          backgroundColor: `${theme.palette.card.main}`, // Optional: background color for the page
          borderRadius: 2, // Rounded corners
          boxShadow: 1, // Add shadow for a subtle effect
        }}
      >
        <Typography variant="h4" sx={{ marginBottom: 1, fontWeight: "bold", color: `${theme.palette.card.contrastText}` }}>
          FS Client Portal
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: 2, color: `${theme.palette.card.contrastText}` }}>
          You don't have access to this site <Divider/> Please contact the administrator
        </Typography>
        <Divider sx={{ marginBottom: 2, backgroundColor: `${theme.palette.card.contrastText}` }} />
      </Box>
    </Container>
  );
};

export default AccessDeniedComponent;
