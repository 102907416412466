
// GoogleLoginComponent.js
import React, { useEffect, useState } from "react";
//import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import { Box, Grid, Backdrop, CircularProgress, Container } from '@mui/material';
import axios from "axios";
import theme from "../theme.js";
/*
const GoogleLoginButton = ({ setHasAccess }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      setIsLoading(true); // Start loading
      try {
        console.log("Login Success:", response);
        const googleToken = response.code;

        // Send the Google token to the backend to create or fetch the user
        const host_origin = window.location.origin;
        let google_login_url = host_origin + "/api/google-login";
        if (host_origin.includes("localhost")) {
          google_login_url = "http://127.0.0.1:5000/api/google-login";
        }

        const res = await axios.post(google_login_url, {
          token: googleToken,
        });
        console.log("User Created or Fetched:", res.data);

        // Store user data in sessionStorage
        sessionStorage.setItem("user", JSON.stringify(res.data.user));
        sessionStorage.setItem("jwt_token", res.data.jwt_token);
        setIsLoading(false); // Stop loading

        // Redirect to the dashboard after successful login
        window.location.href = "/dashboard";
      } catch (error) {
        console.error("Error during user creation:", error);
        setHasAccess(false);
      } finally {
        setIsLoading(false); // Stop loading
      }
    },
    onError: (error) => {
      console.error("Login Failed:", error);
    },
    prompt: "select_account", // Force account selection
    flow: 'auth-code',
    scope: "openid email profile"
  });

  return (
    <>
      {*/
/* Full-page loading overlay *//*
}
      <Backdrop
        open={isLoading}
        sx={{
          backgroundColor: '#ffffff', // White background for loading state
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress
          sx={{
            color: theme.palette.card.main, // Blue spinner for consistency
          }}
        />
      </Backdrop>

      {*/
/* Login Button *//*
}
      <Box
        onClick={() => !isLoading && handleLogin()}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: '100%',
          backgroundColor: '#fff',
          border: '1px solid #dadce0',
          color: '#3c4043',
          fontWeight: 'medium',
          borderRadius: '4px', // Make the edges rounded like a button
          padding: '8px 16px', // Add padding for button-like spacing
          cursor: isLoading ? 'not-allowed' : 'pointer',
          opacity: isLoading ? 0.7 : 1,
          transition: 'all 0.3s ease', // Smooth transition for hover effect
          fontFamily: '"Google Sans", Arial, sans-serif',
          fontSize: '14px',
          letterSpacing: '0.25px',
          '&:hover': {
            boxShadow: !isLoading && '0 4px 6px rgba(0, 0, 0, 0.1)', // Hover effect for depth
            backgroundColor: !isLoading && '#f1f3f4', // Subtle background change on hover
          },
          '&:active': {
            backgroundColor: !isLoading && '#e0e0e0', // Darker background on click
          },
        }}
      >
        <Grid container>
          <Grid item xs={1}>
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/f/fa/Microsoft_Azure.svg"
              alt="Azure logo"
              style={{
                height: '18px',
                marginRight: '8px',
                minWidth: '18px',
                width: '18px',
              }}
            />
          </Grid>
          <Grid item xs={11}>
            <span>Sign in with Google</span>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const GoogleLoginComponent = ({ setHasAccess }) => (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <GoogleLoginButton setHasAccess={setHasAccess} />
    </GoogleOAuthProvider>
);

export default GoogleLoginComponent;
*/


const GoogleLoginComponent = ({ setHasAccess }) => {

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {


    const initializeGoogleLogin = () => {
      if (window.google && window.google.accounts) {

          // Clear any previously selected accounts
        window.google.accounts.id.disableAutoSelect();

        window.google.accounts.id.initialize({
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          callback: handleCredentialResponse,
          auto_select: false,
          prompt: "select_account",
          cancel_on_tap_out: true,
        });

        window.google.accounts.id.renderButton(
          document.getElementById("google-signin-button"),
          { theme: "outline", size: "large", text: "signin_with", width: 352 }
        );
      } else {
        console.error("Google Identity Services script not loaded.");
      }
    };

    // Delay initialization to ensure the script is loaded
    const timer = setTimeout(() => initializeGoogleLogin(), 500);
    return () => clearTimeout(timer);
  }, []);

  const handleCredentialResponse = async (response) => {
    setIsLoading(true);
    try {
      console.log("Google Credential Response:", response);
      const googleToken = response.credential;

      // Send the Google token to the backend
      const host_origin = window.location.origin;
      let google_login_url = host_origin + "/api/google-login";
      if (host_origin.includes("localhost")) {
        google_login_url = "http://127.0.0.1:5000/api/google-login";
      }

      const res = await axios.post(google_login_url, {
        token: googleToken,
      });

      console.log("User Created or Fetched:", res.data);

      // Store user data in sessionStorage
      sessionStorage.setItem("user", JSON.stringify(res.data.user));
      sessionStorage.setItem("jwt_token", res.data.jwt_token);

      // Redirect to dashboard
      window.location.href = "/dashboard";
    } catch (error) {
      console.error("Error during user creation:", error);
      setHasAccess(false);
    } finally {
      setIsLoading(false);
    }
  };

  return <Container><div id="google-signin-button"></div></Container>;
};

export default GoogleLoginComponent;
