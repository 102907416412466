import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Grid, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import { Box, Card, CardContent, Typography, Avatar, Divider, Chip } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import theme from '../theme.js';

const PreAssignedRoleForm = ({ mspList }) => {
    const [email, setEmail] = useState('');
    const [roleId, setRoleId] = useState('2');
    const [msspId, setMsspId] = useState('');
    const [mspId, setMspId] = useState('');
    const [error, setError] = useState(null);

    const resetForm = () => {
        setEmail('');
        setRoleId('2');
        setMsspId('');
        setMspId('');
        setError(null);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Prepare data
        const data = {
            email,
            role_id: roleId,
            mssp_id: msspId || null, // Only send mssp_id or msp_id, not both
            msp_id: mspId || null
        };

        try {
            const host_origin = window.location.origin;

                let pre_assigned_role_url = host_origin + "/api/create_pre_assigned_role";
                if (host_origin.includes("localhost")){
                        pre_assigned_role_url = "http://127.0.0.1:5000/api/create_pre_assigned_role"
                }

            const response = await axios.post(pre_assigned_role_url, data);
            if (response.data.success) {
                alert('User added successfully!');
                resetForm();
            } else {
                setError(response.data.message);
            }
        } catch (error) {
            console.error("Error while calling API:", error);
            setError("An error occurred. Please try again.");
        }
    };

    return (

    <Box display="flex" mt={4}>
      <Card sx={{ maxWidth: 800, padding: 1 }}>
        <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          <Avatar sx={{ bgcolor: theme.palette.card.main, mr: 2 }}>
            <ManageAccountsIcon fontSize="medium" />
          </Avatar>
          <Typography variant="h6" >
            Add MSP User
          </Typography>
        </Box>
          <Box mt={2} display="flex" >
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <TextField
                            size="small"
                            label="Email"
                            fullWidth
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ display: "none" }} >
                        <FormControl fullWidth required  >
                            <InputLabel>Role</InputLabel>
                            <Select
                                size="small"
                                value={roleId}
                                onChange={(e) => setRoleId(e.target.value)}
                                label="Role"
                            >
                                <MenuItem value="3">MSP</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {
                        roleId === "2" && (
                        <Grid item xs={12} >

                        <FormControl fullWidth size="small">
                            <InputLabel>MSP</InputLabel>
                            <Select
                                value={mspId}
                                onChange={(e) => setMspId(e.target.value)}
                                label="MSP"
                            >
                                {mspList.map((msp) => (
                                    <MenuItem key={msp.id} value={msp.id}>
                                        {msp.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        </Grid>
                        )
                    }
                    {
                        roleId === "3" && (
                            <Grid item xs={12} >
                                <TextField
                                    size="small"
                                    label="MSSP ID"
                                    fullWidth
                                    value={msspId}
                                    onChange={(e) => setMsspId(e.target.value)}
                                />
                            </Grid>
                        )
                    }
                    {
                    error &&
                        <Grid item xs={12} >
                         <p style={{ color: 'red' }}>{error}</p>
                        </Grid>
                    }
                    <Grid item xs={12}  style={{ textAlign: 'right' }}>
                        <Button type="submit" variant="contained" color="primary">
                            Invite User
                        </Button>
                    </Grid>

                </Grid>
            </form>
          </Box>
        </CardContent>
      </Card>
    </Box>

    );
};

export default PreAssignedRoleForm;
