import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from '../services/AuthProvider';

const useInactivityLogout = (timeout = 900000) => { // you need to update this time
  const navigate = useNavigate();
  const timerRef = useRef(null);

  const { user, logout } = useAuth();

    const handleLogout = async () => {
        await logout();
        navigate('/login');
  };

  const resetTimer = () => {
    // Clear existing timer
    if (timerRef.current) clearTimeout(timerRef.current);

    // Set a new timer
    timerRef.current = setTimeout(() => {
      // Clear session storage and navigate to login
//      sessionStorage.clear();
//      navigate("/login"); // Redirect to login page
        handleLogout();
        navigate('/login');
    }, timeout);
  };

  useEffect(() => {
    // Reset the timer on user interaction
    const events = ["mousemove", "mousedown", "keypress", "scroll", "touchstart"];
    const eventHandler = () => resetTimer();

    // Attach event listeners
    events.forEach((event) => window.addEventListener(event, eventHandler));

    // Set initial timer
    resetTimer();

    // Cleanup event listeners on unmount
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
      events.forEach((event) => window.removeEventListener(event, eventHandler));
    };
  }, [timeout, navigate]);

  return null;
};

export default useInactivityLogout;
